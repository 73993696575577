import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import Login from './views/Login'
import Report from './views/Report'
const Client = () =>
    import ("./views/Client")

import '@fortawesome/fontawesome-free/js/all.js'
import VueCurrencyInput from 'vue-currency-input'
import VueNumeric from 'vue-numeric'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import VueToast from 'vue-toast-notification'
import axios from 'axios'
import i18n from './i18n'
//import VueNativeSock from 'vue-native-websocket'


if (process.env.NODE_ENV == 'development') {
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
} else { // Production
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
    // Vue.BASE_URL = Vue.prototype.BASE_URL = '/dna_gest_com_moraordinateur';
}
// Vue.NOTIF = Vue.prototype.NOTIF = new Audio(Vue.BASE_URL + '/assets/sounds/pristine-609.mp3');
// Vue.SENT = Vue.prototype.SENT = new Audio(Vue.BASE_URL + '/assets/sounds/tweet-416.mp3');




Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(datePicker);
Vue.use(VueToast);




let instance = Vue.$toast.open('You did it!');
instance.dismiss();
Vue.$toast.clear();


import router from './router'
import routerclient from './routerclient'

Vue.config.productionTip = false

const pluginOptions = {
    globalOptions: { currency: null }
};

Vue.use(VueCurrencyInput, pluginOptions)
Vue.use(VueNumeric)

// WebSocket config
// console.log("Starting connection to WebSocket Server")
//Vue.use(VueNativeSock, 'ws://' + window.location.hostname + ':8089', {
//    reconnection: true,
//    reconnectionDelay: 3000,
//    format: 'json'
//})
Vue.$socket = Vue.prototype.$socket;



// this.connection.onopen = function(event) {
//     console.log(event)
//     console.log("Successfully connected to the echo websocket server...")
// }

axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function(response) {
    var user = response.data;

    var vm = null;
    if ((user.users_gest_com_id == '' || user.users_gest_com_id == null || user.users_gest_com_id == undefined) && (user.clients_gc_id == '' || user.clients_gc_id == null || user.clients_gc_id == undefined)) {
        vm = new Vue({
            router,
            render: h => h(Login),
            i18n
        }).$mount('#app');
    } else if (user.statut == "NOT EXIST") { // || user.statut_caisse == "NOT EXIST"
        vm = new Vue({
            router,
            render: h => h(Report, {
                props: {
                    users_info: user
                }
            }),
            i18n
        }).$mount('#app');
    } else if (!(user.clients_gc_id == '' || user.clients_gc_id == null || user.clients_gc_id == undefined)) {
        vm = new Vue({
            router: routerclient,
            render: h => h(Client, {
                props: {
                    users_info: user
                }
            }),
            i18n
        }).$mount('#app');
    } else {
        vm = new Vue({
            router,
            render: h => h(App, {
                props: {
                    users_info: user
                }
            }),
            i18n
        }).$mount('#app');
    }





    router.beforeEach((to, from, next) => {
        vm.$emit('change-load', 'loading', user);
        axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function(response) {
            var user = response.data;

            if (user.users_gest_com_id == '' ||
                user.users_gest_com_id == null ||
                user.users_gest_com_id == undefined ||
                user.statut == "NOT EXIST" ||
                user.statut_caisse == "NOT EXIST"
            ) {
                location.reload();
            } else {
                next();
            }
        });

    })
    router.afterEach(() => {
        vm.$emit('change-load', 'loaded', user);
    })

    vm.$emit('change-load', 'loading', user);
    vm.$emit('change-load', 'loaded', user);
});