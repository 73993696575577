<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~vue-toast-notification/dist/theme-sugar.css";
@import "~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
</style>
<style>
  html,body {
    height: 100vh;
  }
</style>
<template>
  <form id="stock_en_valeur" class="main-wrap h-100">
    <div class="container d-flex h-100">
      <div class="row justify-content-center align-self-center w-100">
        <div class="col-lg-6">
          <div class="card w-70 m-auto" style="vertical-align: center">
            <div class="card-header" style="">
              <!-- <h5 class="h5" v-show="point_vente_id>0">Fond de caisse du {{date_stock}}</h5> -->
              <h5 class="h5">{{ $t('general.Confirm_header') }}</h5>
               <!-- v-show="point_vente_id==0" -->
            </div>
            <div class="card-body bg-white">
              <div class="form-group">
                 <!-- v-show="point_vente_id==0" -->
                <h6 class="h6" >{{ $t('general.Confirm_text_report') }}</h6>
                <!-- <label v-show="point_vente_id>0" for="fond_de_caisse">{{ $t('general.fond_de_caisse') }} *</label>
                <vue-numeric v-show="point_vente_id>0" decimal-separator="." id="fond_de_caisse" currency="Ar" v-bind:min="0" currency-symbol-position="suffix" separator="space" v-bind:precision="2" v-model="fond_de_caisse" class="text-right form-control" required></vue-numeric> -->
                <input
                  type="text"
                  class="d-none"
                  v-model="fond_de_caisse"
                  name="fond_de_caisse"
                />
              </div>
              <input type="hidden" name="date_stock" v-model="date_stock" />
              <!-- <span>Veuillez cliquer sur continuer pour reporter le stock du jour!</span> -->
            </div>
            <div class="card-footer text-muted text-right">
               <!-- <b-button v-show="point_vente_id>0" size="" class="" variant="secondary" @click="submitStock()">
                  Enregistrer le fond de caisse   ----- v-show="point_vente_id==0"
                </b-button> -->
                <b-button  size="" class="" variant="secondary" @click="submitStock()">
                  {{ $t('general.Confirm_button_ok') }}
                </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
      
  </form>
</template>

<script>
import Vue from "vue"
import axios from "axios"
import $ from "jquery"
import moment from "moment"
export default {
  props: {
    users_info: { 
      type: Object,
      default: () => {},
      required: false
    },

  },
  data() {
    return {
      date_stock: moment().format("DD/MM/YYYY"),
      fond_de_caisse: 0,
      statut_caisse: "",
      point_vente_id: "",
    };
  },
  name: "Report",
  methods: {
    submitStock() {
      var link = this.BASE_URL + "/stockenvaleur/addaction";
      axios
        .post(link, $("#stock_en_valeur").serialize())
        .then(function (response) {
          console.log(response);
          //this.message_reponse = response.data.msg;
          if (response.data.error === "") {
            console.log("Enregistrement effectué avec succès!");

            Vue.$toast.open({
              message: "Stock reporté avec succès!",
              type: "success",
              position: "top-right",
              // all of other options may go here
            });
            location.reload();
          } else {
            console.log("Erreur");
            Vue.$toast.open({
              message: response.data.error,
              type: "error",
              position: "top-right",
              // all of other options may go here
            });
          }
        });
    },
  },
  created: function (){
   
    this.statut_caisse = this.users_info.statut_caisse;
    this.point_vente_id = this.users_info.users_gest_com_point_vente_id;
  },
  mounted: function () {},
};
</script>